<template>
  <modal-wrapper
    :id="id"
    size="md"
    :on-modal-show="checkIcs"
  >
    <template #title>
      Click Report
    </template>
    <template #body>
      <p v-if="icsCompany">
        <strong>Attenzione:</strong><br>
        L’impresa ricercata svolge attività a carattere finanziario (ad esempio è una banca, oppure un’impresa di assicurazione).
        <br/>
        Il Click Report non è disponibile per questa tipologia di impresa.
      </p>
      <p v-if="!icsCompany">
        <strong>Attenzione:</strong><br>
        Una volta scaricati i Click Report in omaggio il costo di ogni click report è di {{productCost}}
        che saranno scaricati dal vostro wallet.
        <br/>
        Se la partita IVA utilizzata per richiedere il click report non è presente nel Registro delle
        Imprese sarà addebitato un {{productSearchCost}}.
        <br/>
        Il Click Report è disponibile solo per le società di capitale che hanno depositato almeno un bilancio
      </p>
      <p v-if="!icsCompany">Premere continua per procedere con l'operazione.</p>
      <p class="mt-3 mb-0">
        <strong>Dettaglio della richiesta:</strong>
      </p>
      <p v-if="hasTaxCode"><strong>P. Iva:</strong> {{searchData.taxCode}}</p>
    </template>
    <template #footer>
      <b-button
        variant="danger"
        size="sm"
        @click="closeModal"
        class="me-auto"
      >
        Annulla
      </b-button>
      <b-button
        variant="success"
        size="sm"
        v-if="!icsCompany"
        @click="searchFunction">
        Continua
      </b-button>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import { formatDecimal } from '@/utils/formatterHelper';
import SubjectService from '@/services/subject.service';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ClickReportSearchModal',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
    searchFunction: Function,
    searchData: {
      taxCode: String,
    },
  },
  data() {
    return {
      icsCompany: false,
    };
  },
  computed: {
    hasTaxCode() {
      // console.log('imma searchmodal');
      return isPresent(this.searchData.taxCode);
    },
    productCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_click_report');
      // console.log('price', price);
      return isNotEmpty(price) ? `${formatDecimal(price.amount, 'it')} ${price.currency}` : '';
    },
    productSearchCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_search');
      // console.log('price', price);
      return isNotEmpty(price) ? `${formatDecimal(price.amount, 'it')} ${price.currency}` : '';
    },
  },
  methods: {
    checkIcs() {
      if (this.hasTaxCode) {
        SubjectService.checkIcsCompany(this.searchData.taxCode).then((result) => {
          console.log('check ics', this.searchData.taxCode, result.data);
          this.icsCompany = result.data;
        });
      }
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
