<template>
  <div>
  <form-wizard
    id="user-analysis-wizard"
    @on-change="changeTab"
    shape="tab"
    color="#6c757d"
    :startIndex="startIndex"
    ref="analysisWizard"
  >
    <template #title>
      &nbsp;
    </template>
    <template slot="footer" slot-scope="props">
      <hr />
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 1 && !props.isLastStep && !isModal"
          class="btn btn-primary"
          @click.native="props.prevTab();goBackToSearch()"
        >
          Indietro
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <b-button
          variant="danger"
          @click="abortReport"
          v-if="!props.isLastStep"
          class="wizard-btn me-2"
        >
          Annulla
        </b-button>
        <wizard-button
          v-if="checkNextProps(props)"
          @click.native="props.nextTab()"
          :class="nextButtonComputedClasses"
          class="btn btn-warning"
        >
          Prosegui
        </wizard-button>
        <wizard-button
          v-if="checkPaymentProps(props)"
          @click.native="props.nextTab()"
          :before-change="validatePayment"
          class="btn btn-warning"
        >
          Invia richiesta
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep"
          @click.native="onComplete"
          class="btn btn-success"
        >
          Vai all'elenco dei tuoi acquisti
        </wizard-button>
      </div>
    </template>
<!--    <tab-content-->
<!--      title="Avviso"-->
<!--      icon="fas fa-hand-paper"-->
<!--      class="content-invest"-->
<!--    >-->
<!--      <p>-->
<!--        Centrale Rischi Pagamenti offre vari servizi, incluso il servizio di valutazione del merito-->
<!--        creditizio di persone fisiche e imprese.-->
<!--      </p>-->
<!--      <p>-->
<!--        Possono essere forniti report per qualsiasi persona fisica residente in Italia e per-->
<!--        qualsiasi impresa con sede legale in Italia, anche qualora non risultino iscritte a CRPagamenti.-->
<!--      </p>-->
<!--      <p>-->
<!--        Per le persone fisiche che non sono presenti nel Club dei Virtuosi Centrale Rischi-->
<!--        Pagamenti produrrà un report redatto esclusivamente in base a informazioni esterne a CRPagamenti.-->
<!--        Viceversa, per le persone fisiche presenti nel Club dei Virtuosi, il report conterrà sia-->
<!--        informazioni esterne che dati interni a MF Solvency, come il numero e il dettaglio delle-->
<!--        segnalazioni a carico della persona fisica.-->
<!--      </p>-->
<!--      <p>-->
<!--        I report su persone fisiche e imprese contengono l'MF Scoring del soggetto valutato.-->
<!--        L'MF Scoring rappresenta un’opinione di MF Solvency riguardo al merito-->
<!--        creditizio del soggetto valutato, inteso come la capacità di quel soggetto di onorare-->
<!--        puntualmente le proprie obbligazioni pecuniarie nell’arco dei 12 mesi seguenti alla-->
<!--        generazione del report. Lo Scoring CRP è elaborato sulla base di dati e informazioni-->
<!--        raccolte direttamente od indirettamente da CRP.-->
<!--      </p>-->
<!--      <p>-->
<!--        Tutti i servizi offerti da Centrale Rischi Pagamenti sono regolati dai Termini e-->
<!--        Condizioni del Portale. Ricorda che i report e gli altri documenti generati da CRPagamenti-->
<!--        sono strettamente riservati all’utente che ne ha fatto richiesta e ne è consentita la-->
<!--        divulgazione a terzi solo previo ottenimento del consenso esplicito di Centrale Rischi Pagamenti.-->
<!--      </p>-->
<!--    </tab-content>-->
    <tab-content
      title="Dati soggetto"
      icon="fas fa-user"
      lazy
      :before-change="validateSubject"
    >
      <div v-if="!addNewSubject">
        <h5 class="text-center mb-4">
          <span v-if="isPerson">Ricerca persona</span>
          <span v-if="isCompany">Ricerca azienda</span>
        </h5>
        <b-row>
          <b-col md="8" lg="6" offset-md="2" offset-lg="3">
            <div class="mb-3">
              <v-select
                class="vs--wizard"
                placeholder="Inserire la p.iva, il codice fiscale o il nome"
                :options="options"
                @search="onSearch"
                v-model="currentSelection"
              >
                <div slot="no-options">Nessuna opzione disponibile</div>
              </v-select>
            </div>
            <div class="text-md-right">
              <b-button
                @click="createNew"
                class="btn-sm"
                variant="warning"
              >
                <span v-if="isPerson">Inserisci una nuova persona</span>
                <span v-if="isCompany">Inserisci una nuova azienda</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="addNewSubject">
        <h5 class="text-center mb-4">
          <span v-if="isPerson">Inserimento persona</span>
          <span v-if="isCompany">Inserimento azienda</span>
        </h5>
        <!--        </p>-->
        <person-data
          v-if="isPerson"
          :name-mandatory="insertFullInfo"
          :person="formAnalysis.person"
        />
        <company-data
          v-if="isCompany"
          :company="formAnalysis.company"
        />
      </div>
    </tab-content>
    <tab-content
      title="Selezione prodotto"
      icon="fas fa-list-ul"
      v-if="hasProductSearch"
    >
      <div v-if="!productListSearchState">
        <div
          v-if="productsDisclaimer"
          class="py-3"
        >
          <h6 class="text-center">
            <strong>ATTENZIONE</strong>
          </h6>
          <p class="text-center">
            <strong>
              Continuando con la ricerca si accetta la spesa fissa di {{searchPriceComp}}.
            </strong>
            <br>
            Qualora il prodotto richiesto sia già stato acquistato in precedenza, non sarà
            necessario effettuare ulteriori transazioni.
          </p>
          <div class="mt-3 text-center">
            <b-btn @click="doProductSearch">ACCETTO</b-btn>
          </div>
        </div>
        <div v-if="loadingProducts" class="p-5">
          <loader-info />
        </div>
        <div v-if="productListError">
          Si è verificato un errore nella ricerca. Riprova più tardi.
        </div>
      </div>
      <div v-else>
        <div v-if="balances.length > 0">
          <h5>Anni disponibili</h5>
          <div class="product-list product-list__modal">
            <product-table-item-wizard
              v-for="(item, index) in balances"
              :key="item.descCodAtto.toString()+'-'+item.codAtto+'-'+index"
              :item="item"
              :buy-action="buyItem"
            />
          </div>
        </div>
        <div v-else>
          <p class="my-4 text-center">
            <em>
              Nessun bilancio disponibile per l'azienda selezionata
            </em>
          </p>
        </div>
      </div>
    </tab-content>
    <tab-content
      title="Pagamento"
      icon="fas fa-euro-sign"
    >
      <payment-section :entity="entity" :request-price="requestPrice" :has-funds="hasFund"/>
    </tab-content>
    <tab-content
      title="Inviata"
      icon="fas fa-paper-plane"
    >
      <analysis-request-send
        :submitted="submitted"
        :completed="completed"
        :has-error="hasError"
        :report-name="reportName"
      />
    </tab-content>
  </form-wizard>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import {
  checkTaxCode, isNotEmpty, isPresent, validateVat,
} from '@/utils/validators';
import { searchCompany, searchPerson } from '@/utils/selectSearch';
import LoaderInfo from '../helpers/LoaderInfo.vue';
import { formatCurrency } from '@/utils/formatterHelper';

const PersonData = () => import('@/components/wizards/userReportSections/PersonData.vue');
const CompanyData = () => import('@/components/wizards/userReportSections/CompanyData.vue');
const AnalysisRequestSend = () => import('@/components/wizards/userAnalysisSections/AnalysisRequestSend.vue');
const PaymentSection = () => import('@/components/wizards/userAnalysisSections/PaymentSection.vue');
const ProductTableItemWizard = () => import('@/components/productTable/ProductTableItemWizard.vue');

export default {
  name: 'UserAnalysisWizard',
  components: {
    ProductTableItemWizard,
    LoaderInfo,
    AnalysisRequestSend,
    CompanyData,
    PersonData,
    PaymentSection,
  },
  props: {
    id: String,
    requestType: String,
    requestProduct: String,
    requestProductItem: Object,
    modalId: String,
    productDescription: String,
    hasSubject: Boolean,
    entityType: String,
    profileEntity: Object,
    hasProductSearch: Boolean,
    extendedSearchData: Object,
  },
  data() {
    return {
      entity: {
        entityType: null,
      },
      startIndex: this.hasSubject ? 1 : 0,
      formAnalysis: {
        person: {
          name: '',
          surname: '',
          taxCode: '',
          // email: '',
        },
        company: {
          companyName: '',
          vatNumber: '',
          email: '',
        },
      },
      submitted: false,
      completed: false,
      hasError: false,
      reportCreated: null,
      userChosenType: this.requestType || '',
      options: [],
      // currentSelection: null,
      // addNewSubject: false,
      addNewSubject: true,
      currentSelection: {},
      paymentFailed: false,
      productListSearchState: false,
      productsDisclaimer: true,
      loadingProducts: false,
      productListError: false,
      chosenSubItem: {},
      searchPriceComp: '',
    };
  },
  computed: {
    isLoadingBalance() {
      return this.$store.getters['analysis/isLoadingBalance'];
    },
    balances() {
      return this.$store.getters['analysis/balanceAvailableList'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    isCompany() {
      // console.log('this.requestType comp', this.requestType);
      if (isNotEmpty(this.requestType)) return this.requestType === 'pr_company';
      return this.entity.entityType === 'company';
    },
    isPerson() {
      // console.log('this.requestType pers', this.requestType);
      if (isNotEmpty(this.requestType)) return this.requestType === 'pr_person';
      return this.entity.entityType === 'person';
    },
    reportId() {
      return this.reportCreated || '';
    },
    baseButtonClasses() {
      return 'btn-wizard--large';
    },
    requestClassesCompany() {
      if (this.userChosenType === 'pr_company') return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    requestClassesPerson() {
      if (this.userChosenType === 'pr_person') return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    nextButtonComputedClasses() {
      if (this.userChosenType === '') {
        return 'disabled';
      }
      return '';
    },
    reportName() {
      return this.reportCreated?.reportName || '';
    },
    requestPrice() {
      return this.$store.getters['wallet/getPricing'](this.requestType, this.requestProduct)?.price;
    },
    hasFund() {
      // console.log('check fund');

      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      // console.debug('my wallet', myWallet);
      return (myWallet.amount.amount - this.requestPrice.amount) >= 0;
    },
    isModal() {
      return isNotEmpty(this.modalId);
    },
    insertFullInfo() {
      return this.requestProductItem?.additionalInfos?.name;
    },
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    console.log('tets', this.balances.length);
    this.$store.dispatch('analysis/cleanBalanceAvailableList');
    this.$store.dispatch('wallet/loadPricingProduct');
    if (isNotEmpty(this.itemKey)) {
      if (this.itemKey === 'pr_company') {
        this.entity.entityType = 'company';
      } else {
        this.entity.entityType = 'person';
      }
      // console.log('mount set', this.entity.entityType);
    }
    // console.log(
    //   'this.profileEntity pre',
    //   this.profileEntity,
    //   this.requestProduct,
    //   this.productDescription,
    // );
    if (isNotEmpty(this.profileEntity)) {
      // console.log('notEmpty this.profileEntity', this.profileEntity);
      if (this.entityType === 'company') {
        // console.log('entytype c', this.profileEntity);
        this.formAnalysis.company = {
          companyName: this.profileEntity.companyName,
          vatNumber: this.profileEntity.vatNumber,
          email: this.profileEntity.email,
        };
      }
    }
    // console.log('this.profileEntity post', this.profileEntity);
    this.decodePricing();
  },
  methods: {
    onComplete() {
      if (!this.$route.path.includes('/user-analysis-history')) {
        this.$router.push('/user-analysis-history');
      }
    },
    abortReport() {
      this.$confirm(
        {
          message: 'Sei sicuro di voler annullare la richiesta?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              if (isNotEmpty(this.modalId)) {
                this.$bvModal.hide(this.modalId);
              } else {
                this.$router.replace('/dashboard-utente');
              }
            }
          },
        },
      );
    },
    validateSubject() {
      // if (!this.currentSelection) {
      //   return false;
      // }
      if (this.isCompany) {
        console.log('check company');
        if (this.currentSelection?.company) {
          const {
            vatNumber,
          } = this.currentSelection.company.info;
          this.formAnalysis.company.companyName = vatNumber;
          this.formAnalysis.company.vatNumber = vatNumber;
        }
        console.log('check company', this.formAnalysis.company);
        const {
          vatNumber,
        } = this.formAnalysis.company;
        return validateVat(vatNumber); // && isPresent(companyName) && isEmail(email);
      }
      if (this.isPerson) {
        if (this.currentSelection?.person) {
          const {
            anagraficalInfo,
          } = this.currentSelection.person;
          if (this.insertFullInfo) {
            this.formAnalysis.person.name = anagraficalInfo.name;
            this.formAnalysis.person.surname = anagraficalInfo.surname;
          } else {
            this.formAnalysis.person.name = anagraficalInfo.taxCode;
            this.formAnalysis.person.surname = anagraficalInfo.taxCode;
          }
          this.formAnalysis.person.taxCode = anagraficalInfo.taxCode;
        }
        const {
          name,
          surname,
          taxCode,
        } = this.formAnalysis.person;
        if (this.insertFullInfo) {
          return isPresent(name) && isPresent(surname) && checkTaxCode(taxCode); // && isEmail(email);
        }
        return checkTaxCode(taxCode); // && isEmail(email);
      }
      return false;
    },
    validateProduct() {
      return isPresent(this.requestType) && isPresent(this.requestProduct) && isPresent(this.entity.entityType);
    },
    validatePayment() {
      // console.log('validate payment');
      return this.hasFund;
    },
    changeTab(oldIndex, newIndex) {
      // console.log('newIndex', newIndex);
      if (this.hasProductSearch && newIndex === 3) {
        this.createRequest();
      }
      if (!this.hasProductSearch && newIndex === 2) {
        this.createRequest();
      }
    },
    loadProducts() {
      // console.log('loadProducts', this.requestType);
      if (isNotEmpty(this.requestType)) {
        this.requestProduct = null;
        if (!this.$store.getters['tableUtils/hasTableList'](this.requestType)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](this.requestType)) {
            this.$store.dispatch('tableUtils/loadTableList', {
              tableName: this.requestType,
              language: 'it',
            });
          }
        }
      }
    },
    setRequestType(val) {
      // console.log('setRequestType val', val);
      this.userChosenType = val;
      this.requestType = val;
      this.loadProducts();
    },
    goBackToSearch() {
      this.currentSelection = {};
      // this.addNewSubject = false;
    },
    createNew() {
      this.currentSelection = {};
      this.addNewSubject = true;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    createRequest() {
      const { person, company } = this.formAnalysis;

      if (this.isCompany) {
        company.companyName = company.vatNumber;
      }
      if (this.isPerson && !this.insertFullInfo) {
        person.name = person.taxCode;
        person.surname = person.taxCode;
      }

      const subject = this.isPerson ? person : company;
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerRequestType;
      let ownerRequestId;

      if (isNotEmpty(currentProfile)) {
        ownerRequestType = 'COMPANY';
        ownerRequestId = currentProfile.companyId;
      } else {
        ownerRequestType = 'PERSON';
        ownerRequestId = this.$store.getters['subject/currentPerson'].personId;
      }

      let reportRequest;
      if (this.hasProductSearch) {
        reportRequest = {
          ...subject,
          requestProduct: this.requestProduct,
          requestType: this.requestType,
          ownerRequestType,
          ownerRequestId,
          requestProductParams: { ...this.chosenSubItem, taxCode: company?.vatNumber },
        };
      } else {
        reportRequest = {
          ...subject,
          requestProduct: this.requestProduct,
          requestType: this.requestType,
          ownerRequestType,
          ownerRequestId,
        };
      }

      const subjectDesc = subject.companyName || `${subject.name} ${subject.surname}`;
      const description = `Acquisto prodotto ${this.productDescription} per ${subjectDesc}`;
      this.completed = false;
      this.submitted = true;
      this.$store.dispatch('analysis/createUserAnalysis', reportRequest)
        .then((reportCreated) => {
          this.reportCreated = reportCreated.data;
          this.$store.dispatch('wallet/createTransaction', {
            entityType: ownerRequestType,
            entityId: ownerRequestId,
            description,
            productType: this.requestType,
            productCode: this.requestProduct,
            paymentFor: {
              entityClass: 'org.trendframework.database.entity.SubjectAnalysisRequest',
              entityId: this.reportCreated.analysisId,
            },
          }).then((txCreated) => {
            console.log('txCreated', txCreated);
            this.$store.dispatch('wallet/loadWallet', { entityType: ownerRequestType.toLowerCase(), entityId: ownerRequestId });
            this.completed = true;
            this.submitted = false;
          }).catch((errorTx) => {
            console.log('errorTx', errorTx);
            this.paymentFailed = true;
            this.completed = true;
            this.submitted = false;
          });
        },
        (error) => {
          console.error('Errore in creazione segnalazione', error);
          this.completed = false;
          this.submitted = false;
        });
    },
    search: debounce((loading, search, vm) => {
      let request = null;

      const products = vm.$store.getters['tableUtils/getTableList'](vm.requestType) || [];

      const product = products.filter((item) => item.key === vm.requestProduct);
      const customFilter = {};
      if (product.length > 0) {
        const { additionalInfos } = product[0];
        const { connectedCompany } = additionalInfos;
        customFilter.connectedCompany = connectedCompany;
      }
      if (vm.isCompany) {
        request = searchCompany(search, customFilter);
      } else if (vm.isPerson) {
        request = searchPerson(search);
      }
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        // console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', vm.userChosenType, err);
        loading(false);
      });
    }, 350),
    doProductSearch() {
      // console.log('-1€ > do search');
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerRequestType;
      let ownerRequestId;

      if (isNotEmpty(currentProfile)) {
        ownerRequestType = 'COMPANY';
        ownerRequestId = currentProfile.companyId;
      } else {
        ownerRequestType = 'PERSON';
        ownerRequestId = this.$store.getters['subject/currentPerson'].personId;
      }
      // onsearch promise
      // this.productsDisclaimer = false;
      // this.loadingProducts = true;
      this.productListSearchState = false;
      const { company } = this.formAnalysis;

      const searchBalance = {
        entityType: ownerRequestType,
        entityId: ownerRequestId,
        taxCode: company.vatNumber,
        productConnectType: this.requestType,
        productConnectCode: this.requestProduct,
      };
      this.$store.dispatch('analysis/readBalanceAvailable', searchBalance).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          // onsearchDone callback
          this.productListSearchState = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          // searchError callback
          this.productListError = true;
        },
      );
    },
    checkNextProps(props) {
      // console.log('props', props);
      // !props.isLastStep && props.activeTabIndex < 2
      if (this.hasProductSearch) {
        if (props.activeTabIndex === 1) {
          // return this.productListSearchState === true;
          return false;
        }
        return !props.isLastStep && props.activeTabIndex < 2;
      }
      return !props.isLastStep && props.activeTabIndex < 1;
    },
    checkPaymentProps(props) {
      // !props.isLastStep && props.activeTabIndex === 2 && hasFund
      if (this.hasProductSearch) {
        return !props.isLastStep && props.activeTabIndex === 2 && this.hasFund;
      }
      return !props.isLastStep && props.activeTabIndex === 1 && this.hasFund;
    },
    recharge() {
      this.$bvModal.show('paymentModal');
    },
    buyItem(item) {
      // console.log('buyItem id', item);
      this.chosenSubItem = item;
      this.$refs.analysisWizard.nextTab();
    },
    decodePricing() {
      // console.log('decodePricing', item);
      const pricing = this.$store.getters['wallet/getPricing'](this.requestType, this.requestProduct);
      // console.log('decodePricing modal', pricing, isNotEmpty(this.extendedSearchData), this.extendedSearchData);
      if (pricing) {
        if (isNotEmpty(this.extendedSearchData) && this.searchPriceComp === '') {
          if (this.extendedSearchData.hasPricing) {
            // console.log('decodePricing extendedSearchData', this.extendedSearchData);
            const { searchCode, searchType } = this.extendedSearchData;
            const searchPrice = this.$store.getters['wallet/getPricing'](searchType, searchCode);
            // console.log('searchPrice', searchPrice);
            // console.log('searchPrice amount', searchPrice.price.amount, searchPrice.price.currency);
            if (searchPrice && searchPrice.price.amount > 0) {
              pricing.price.amount += searchPrice.price.amount;
              this.searchPriceComp = formatCurrency(searchPrice.price.amount, 'it', searchPrice.price.currency);
            }
          }
        }
        return formatCurrency(pricing.price.amount, 'it', pricing.price.currency);
      }
      return 'NA';
    },
  },
};
</script>

<style scoped>

</style>
